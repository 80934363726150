<!--
 * @Author: your name
 * @Date: 2021-08-16 16:28:16
 * @LastEditTime: 2021-09-07 14:50:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\basicPage\safety\SafetyPatrol.vue
-->
<template>
  <div>
    <qualityList title="安全巡检列表" :type="7"></qualityList>
  </div>
</template>
<script>
import qualityList from '../quality/qualityCommon.vue';
export default {
  components: {
    qualityList
  },
  data() {
    return {};
  }
};
</script>
